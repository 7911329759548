@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';

.DesktopScrollContainer {
  position: relative;

  &__nav {
    display: flex;
    gap: tokens.bpk-spacing-md();

    @include breakpoints.bpk-breakpoint-mobile {
      display: none;
    }

    &--top {
      position: absolute;
      top: -(36 + 12) * tokens.$bpk-one-pixel-rem;
      right: 0;

      @include utils.bpk-rtl {
        right: unset;
        left: 0;
      }
    }

    &--bottom {
      position: relative;
      margin-top: tokens.bpk-spacing-lg();
    }

    button {
      width: tokens.$bpk-button-height;
      height: tokens.$bpk-button-height;
      padding: 0;
    }
  }

  &__container {
    display: flex;
    overflow: hidden;
    margin-inline: 0 - 1.5 * tokens.bpk-spacing-md();
  }

  &__card {
    flex-shrink: 0;

    @include breakpoints.bpk-breakpoint-above-tablet {
      width: calc(100% / 3);
    }

    @include breakpoints.bpk-breakpoint-tablet {
      width: 50%;
    }

    @include breakpoints.bpk-breakpoint-small-tablet-only {
      width: 100%;
    }

    @include breakpoints.bpk-breakpoint-mobile {
      display: none;
    }

    &--single {
      width: 100%;
    }
  }

  &__bottomNav {
    @include breakpoints.bpk-breakpoint-mobile {
      display: none;
    }

    [class*='BpkPageIndicator_bpk-page-indicator__indicator--default']:hover {
      cursor: pointer;
    }

    [class*='BpkPageIndicator_bpk-page-indicator__indicator--active-default'] {
      pointer-events: none;
    }
  }
}
