@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';

.PopularDestinations {
  &__header {
    margin-bottom: 1.5 * tokens.bpk-spacing-md();
    margin-inline-end: 104 * tokens.$bpk-one-pixel-rem;

    @include breakpoints.bpk-breakpoint-mobile {
      max-width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }

  div[class*='DesktopScrollContainer__card'] {
    @media screen and (min-width: 42.625rem) and (max-width: 48rem) {
      width: 50%;
    }

    @media screen and (min-width: 32rem) and (max-width: 42.625rem) {
      width: 100%;
    }
  }

  div[class*='bpk-mobile-scroll-container--left-indicator']::before {
    background-image: none;
  }

  div[class*='bpk-mobile-scroll-container--right-indicator']::after {
    background-image: none;
  }

  &__mobile {
    @include breakpoints.bpk-breakpoint-above-mobile {
      display: none;
    }
  }

  &__mobileCardList {
    display: block;
  }

  &__scroll__list {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    @include breakpoints.bpk-breakpoint-mobile {
      flex-wrap: nowrap;
      margin-inline-start: tokens.$bpk-one-pixel-rem * 1;
    }

    div[class*='DestinationContainer__card'] {
      @include breakpoints.bpk-breakpoint-mobile {
        width: 312 * tokens.$bpk-one-pixel-rem;
      }
    }
  }

  &__mobileCardList__container {
    height: 100%;
    margin-bottom: tokens.bpk-spacing-lg();
  }

  &__scroll__container {
    height: 100%;
    padding: 1.5 * tokens.bpk-spacing-md() 1.5 * tokens.bpk-spacing-md()
      tokens.bpk-spacing-lg();

    @include breakpoints.bpk-breakpoint-mobile {
      height: auto;
      margin-bottom: 1 * tokens.$bpk-one-pixel-rem;
      padding: tokens.bpk-spacing-sm() tokens.bpk-spacing-base() 0 0;

      @include utils.bpk-rtl {
        padding: tokens.bpk-spacing-sm() 0 0 tokens.bpk-spacing-base();
      }
    }
  }

  &__desktop {
    @include breakpoints.bpk-breakpoint-mobile {
      display: none;
    }
  }

  &__cardlist {
    display: flex;
    margin-top: tokens.bpk-spacing-lg();
    flex-flow: row wrap;
    margin-inline-end: 0 - tokens.bpk-spacing-lg();
  }

  &__cardlist__container {
    margin-bottom: tokens.bpk-spacing-lg();
    padding-inline-end: tokens.bpk-spacing-lg();

    @include breakpoints.bpk-breakpoint-above-tablet {
      width: 33.3%;
    }

    @include breakpoints.bpk-breakpoint-tablet {
      width: 50%;
    }

    @include breakpoints.bpk-breakpoint-mobile {
      display: none;
    }

    @media screen and (min-width: 32rem) and (max-width: 42.625rem) {
      width: 100%;
    }
  }

  &__footer {
    @include breakpoints.bpk-breakpoint-mobile {
      display: none;
    }
  }

  &__ioWrapper {
    height: 100%;
  }
}

.SelectableChip {
  width: 100%;
  padding: 12 * tokens.$bpk-one-pixel-rem 0;

  &__item {
    display: inline-block;
    margin-inline-end: tokens.bpk-spacing-md();
  }
}
