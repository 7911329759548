@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

.DestinationContainer {
  &__card {
    height: 100%;
  }

  &__card-with-width {
    height: 100%;

    @include breakpoints.bpk-breakpoint-mobile {
      width: 312 * tokens.$bpk-one-pixel-rem;
    }
  }

  &__image {
    border-radius: 0.5rem 0.5rem 0 0;
    overflow: hidden;

    img {
      margin: auto;
    }

    div[class*='bpk-image--no-background'] {
      padding-bottom: 46.9388%;

      @include breakpoints.bpk-breakpoint-mobile {
        padding-bottom: 59% !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }

  &__content {
    display: flex;
    padding: tokens.bpk-spacing-base();
    justify-content: space-between;
    align-items: flex-start;
  }

  &__leftSection {
    text-align: start;
    white-space: normal;
  }

  &__additionalInfo {
    color: tokens.$bpk-text-secondary-day;
  }

  &__rightSection {
    flex: auto;
    text-align: end;
    margin-inline-start: tokens.bpk-spacing-md();

    div[class*='BpkPrice_bpk-price'] {
      white-space: nowrap;
    }
  }
}
